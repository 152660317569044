/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { useEffect, useState } from 'react';

import useApi from 'api/hooks/useApi';
import acceptVccTerms from 'api/onboarding/acceptVccTerms';
import sendVccDigits from 'api/onboarding/sendVccDigits';

import LoadingIndicator from 'components/LoadingIndicator';

import BodyText from 'components/BodyText';
import Button from 'components/Button';
import HeaderText, { HeaderTextVariants } from 'components/HeaderText';
import useWindowWidth from 'hooks/browser/useWindowWidth';

import Alert from 'components/Alert';


import {
  MailToCustomerSupport,
  MailToPaymentSupport
} from 'config/messageLinks';

import BlueHeaderOnWhiteLayout from 'layouts/BlueHeaderOnWhite';

import activeEyeImage from 'public/images/icons/eye-active.svg?url';
import hideEyeImage from 'public/images/icons/eye-hide.svg?url';
import inactiveEyeImage from 'public/images/icons/eye-inactive.svg?url';
import greenCheckmark from 'public/images/icons/green-checkmark.svg?url';

import TermsScrollWrap from 'containers/TermsScrollWrap';

import './index.scss';


const LargePasswordBox = ({
  isShown,
  children,
  onClick
}) => {
  let icon = inactiveEyeImage;

  if (children) {
    icon = isShown ? activeEyeImage : hideEyeImage;
  }

  return (
    <div className="large-password-box">
      {children ? (
        <div className="active">
          {isShown ? (
            <span className="password">
              {children}
            </span>
          ) : (
            <span className="dots">
              &#9679;&nbsp;&#9679;&nbsp;&#9679;&nbsp;&#9679;&nbsp;&#9679;&nbsp;&#9679;
            </span>
          )}
        </div>
      ) : (
        <div className="inactive dots">
          &#9679;&nbsp;&#9679;&nbsp;&#9679;&nbsp;&#9679;&nbsp;&#9679;&nbsp;&#9679;
        </div>
      )}
      {isShown && children ? (
        <span className="color-failure pad-8-left">
          Save This Number
        </span>
      ) : null}
      <div className="display-toggle">
        <img
          onClick={onClick}
          src={icon}
          style={{ cursor: 'pointer' }}
        />
      </div>
    </div>
  );
};

export const InvalidAcceptVccTerms = () => {
  return (
    <BlueHeaderOnWhiteLayout
      contentWidth={650}
      hideLinks={true}
    >
      <div className="viewstrap">
        <div className="text-center">
          <HeaderText className="pad-12-bottom">
            This is link no longer valid
          </HeaderText>
          <HeaderText className="pad-12-bottom" variant={HeaderTextVariants.Small}>
            Reach out to our {MailToCustomerSupport('support team')} if you need assistance
            getting access to your 6 digits.
          </HeaderText>
        </div>
      </div>
    </BlueHeaderOnWhiteLayout>
  );
};

function ResendSixDigits({ secureToken }) {
  const api = useApi(sendVccDigits, 'ResendVccDigits');

  useEffect(() => {
    /// immediately email 6 digits when this page loads
    api.call({
      secureToken
    });
  }, []);

  if (api.isLoading()) {
    return <LoadingIndicator />;
  }

  return (
    <div className="text-center pad-20-top">
      <img src={greenCheckmark} />
      <HeaderText className="pad-8-y" variant={HeaderTextVariants.Medium}>
        Your 6-Digit Card Prefix was Sent
      </HeaderText>

      <BodyText>
        An email containing the 6-digit prefix needed to process virtual card payments
        has been sent to the email address on file.
        <br/><br/>
        Please save the 6-digit number. You will need it to process future payments.
        <br/><br/>
        If you have question or need assistance, please contact our {MailToPaymentSupport('support team')}.
      </BodyText>
    </div>
  );
}

function AcceptVccTerms({
  isAssistedPay,
  customerCompany,
  remitToEmail,
  secureToken,
  onAcceptComplete
}) {

  const api = useApi(acceptVccTerms, 'AcceptVccTerms', {
    onComplete: onAcceptComplete
  });

  let [isScrolled, setScrolled] = useState(false);
  let [acceptError, setAcceptError] = useState();

  useEffect(() => {
    if (!acceptError) return;

    if (isScrolled) {
      setAcceptError(null);
    }
  }, [isScrolled]);

  const onAccept = () => {
    if (!isScrolled) {
      setAcceptError('You must scroll to the end of the terms to continue.');
      return;
    }

    api.call({
      secureToken
    });
  };

  return (
    <div className="pad-20-x">
      <HeaderText className="pad-12-bottom">
        Review and Accept Payment Terms
      </HeaderText>

      <HeaderText className="pad-12-bottom" variant={HeaderTextVariants.Small}>
        Please review our Payment Terms and accept them below to receive card payments from <strong>
          {customerCompany}
        </strong>.
      </HeaderText>
      <TermsScrollWrap onScrollToEnd={() => setScrolled(true)} />
      {acceptError
        ? (
          <Alert variant="failure">
            {acceptError}
          </Alert>
        )
        : null}

      <br/><br/>

      <HeaderText className="pad-12-bottom" variant={HeaderTextVariants.Small}>
        {isAssistedPay
          ? <>
            Viewpost will assist in processing payments based
            on the preferences you discussed with our payment specialist.
          </>
          : <>Payment instructions will be emailed to <strong>{remitToEmail}</strong>.</>}
      </HeaderText>

      <br/><br/>

      <HeaderText className="pad-12-bottom" variant={HeaderTextVariants.Small}>
        If you have any questions, please contact our {MailToCustomerSupport('support team')}.
      </HeaderText>

      <div className="pad-20-top">
        <Button
          isDisabled={api.isLoading()}
          isProcessing={api.isLoading()}
          style={{ display: 'block' }}
          onClick={onAccept}>
          I have reviewed and accept the Payment Terms
        </Button>
      </div>

    </div>
  );
}

function TermsAccepted({
  isAssistedPay,
  customerCompany,
  cardBrand,
  sixDigitPrefix,
  showDigitsImmediately = false
}) {
  let [isShown, setShown] = useState(showDigitsImmediately);
  return (
    <div className="pad-20-x">
      <HeaderText className="pad-12-bottom">
        You're All Set
      </HeaderText>

      {isAssistedPay
        ? (
          <HeaderText className="pad-12-bottom" variant={HeaderTextVariants.Small}>
            Viewpost will ensure that payments from <strong>{customerCompany}</strong> are
            processed quickly and efficiently.
            <br /><br />
            If you have questions, please contact our {MailToCustomerSupport('support team')} — we're here to help.
          </HeaderText>
        )
        : null}

      {!isAssistedPay
        ? (
          <div>
            <HeaderText className="pad-20-bottom" variant={HeaderTextVariants.Small}>
              Below is the unique 6-digit {cardBrand} card prefix that you will use
              to process card transactions from <strong>{customerCompany}</strong>. Please save this number.
            </HeaderText>
            <div className="pad-20-top">
              <LargePasswordBox isShown={isShown} onClick={() => setShown(!isShown)}>
                {sixDigitPrefix}
              </LargePasswordBox>
            </div>
            <div className="pad-20-x pad-20-top">
              <BodyText>
                <strong>Additional payment instructions have been sent to the email address on file</strong>.
                <br/>
                If you have questions about payments,
                please contact our {MailToCustomerSupport('support team')}.
              </BodyText>
            </div>
          </div>
        )
        : null}
    </div>
  );
}

function TermsAcceptedWithOpenVccPayment({
  isAssistedPay,
  customerCompany
}) {
  return (
    <div className="pad-20-x">

      {isAssistedPay
        ? (
          <div>
            <HeaderText className="pad-12-bottom">
              You're Setup to Receive Card Payments!
            </HeaderText>
            <HeaderText className="pad-12-bottom" variant={HeaderTextVariants.Small}>
              Viewpost will ensure that payments from <strong>{customerCompany}</strong> are
              processed quickly and efficiently.
              <br /><br />
              If you have questions, please contact our {MailToCustomerSupport('support team')} — we're here to help.
            </HeaderText>
          </div>
        )
        : null}

      {!isAssistedPay
        ? (
          <div style={{textAlign: 'center'}}>
            <HeaderText className="pad-12-bottom">
              You're Setup to Receive Card Payments!
            </HeaderText>
            <div style={{margin: '30px 0px'}}>
              <img src={greenCheckmark} height={60} width={60} />
            </div>
            <HeaderText className="pad-20-bottom" variant={HeaderTextVariants.Small}>
              <strong>{customerCompany}'s</strong> card payments will be sent
              to the email address on file.
            </HeaderText>
            <div>
              <BodyText>
                <br />
                If you have questions about payments,
                please contact our {MailToCustomerSupport('support team')}.
              </BodyText>
            </div>
          </div>
        )
        : null}
    </div>
  );
}

// This page also does double duty to also service the 'resend my digits' action
const AcceptVccTermsLandingPage = ({
  context,
  secureToken,
  secureTokenContext: {
    data: {
      cardBrand,
      customerCompany,
      // Email will only be there until you accept terms, as it could update later
      email,
      hasAcceptedTerms: previouslyAcceptedTerms,
      isAssistedPay,

      hasOpenVccPayment,

      // will not be available prior to accepting terms
      // automatic update of token after acceptVccTerms call (via api config's "markUpdated" functionality)
      // should bring it in
      sixDigitPrefix
    }
  }
}) => {
  const [isAccepted, setAccepted] = useState(previouslyAcceptedTerms);
  const windowWidth = useWindowWidth();

  // this landing page is used for 2 different token scenarios
  // const isAcceptVccTermsToken = context === 'VccAcceptTerms';
  const isResendToken = context === 'VccResendSixDigits';


  let content;
  if (isResendToken) {
    content = <ResendSixDigits secureToken={secureToken} />;
  } else if (hasOpenVccPayment && isAccepted) {
    content = <TermsAcceptedWithOpenVccPayment isAssistedPay={isAssistedPay}
      customerCompany={customerCompany} />;
  } else if (isAccepted) {
    content = <TermsAccepted isAssistedPay={isAssistedPay}
      customerCompany={customerCompany}
      sixDigitPrefix={sixDigitPrefix}
      cardBrand={cardBrand}
      showDigitsImmediately={!previouslyAcceptedTerms} />;
  } else {
    content = <AcceptVccTerms isAssistedPay={isAssistedPay}
      customerCompany={customerCompany}
      remitToEmail={email}
      secureToken={secureToken}
      onAcceptComplete={() => setAccepted(true)} />;
  }

  return (
    <BlueHeaderOnWhiteLayout
      contentWidth={windowWidth > 1000 ? 1000 : 'auto'}
      hideLinks={true}>
      <div className="viewstrap">
        {content}
      </div>
    </BlueHeaderOnWhiteLayout>
  );
};

export default AcceptVccTermsLandingPage;